html {
  --primary: #2552F3;
  --primary-color-10: #EFF3FF;
  --primary-color-dark: #2D2C4C;
  --primary-color-100: #35B678;
  --secondary-color: #F3F4F5;
  --secondary: #172541;
  --error-color: #FF3F4A;
  --danger-color: #FF3F4A;
  --success-color: #79BD35;
  --warning-color: #FFC200;
  --white: #ffffff;
  --gray: #575670;
  --border-color-base: #F0F0F0;
  --layout-sider-background: #F6F7FB;
  --layout-body-background: #F9FAFC;
  --menu-bg: transparent;
  --menu-inline-submenu-bg: transparent;
  --height-base: 40px;
  --height-lg: 48px;
  --height-sm: 32px;
  --menu-item-padding-horizontal: 20px;
  /* Text Color */
  --text-color-100: #18212C;
  --text-color-80: #63687A;
  --text-color-5: #FBFBFB;
  --text-color: #2D2C4C;
  /* Spaces */
  --layout-header-height: 48px;
  --layout-header-padding: 0 24px;
  --padding-lg: 24px;
  --padding-md: 16px;
  --padding-sm: 12px;
  --padding-xs: 8px;
  --padding-xss: 4px;
  --margin-lg: 24px;
  --margin-md: 16px;
  --margin-sm: 12px;
  --margin-xs: 8px;
  --margin-xss: 4px;
  /* Forms */
  --form-item-label-height: auto;
  --input-height-base: 32px;
  --input-height-lg: 40px;
  --input-height-sm: 24px;
  /* Buttons */
  --btn-height-base: 32px;
  --btn-height-lg: 40px;
  --btn-height-sm: 24px;
  /* Avatar */
  --avatar-group-overlapping: -16px;
  /* Tabs */
  --tabs-bar-margin: 0 0 32px 0;
  /* Heading */
  --heading-2-size: 26px;
  /* Card */
  --card-head-font-size: 26px;
  --card-radius: 8px;
}