@import 'antd/dist/reset.css';

.ant-form-large .ant-form-item-label > label {
  height: auto!important;
}

.wysiwyg-editor {
  min-height: 200px;
  border: 1px solid var(--border-color-base);
  padding: var(--padding-sm);
}

.table-bg-green {
  background-color: #f6ffed;
}

.specialty-row .ant-table-cell:first-child {
  white-space: nowrap;
}

.table-row-new {
  background-color: #e9e9e9;
}

.ant-tooltip-content .ant-sidebar-badge {
  color: #ffffff;
}